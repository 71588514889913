import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import "../identity.css";
import AuthContext from "../../../auth/auth-context";
import RoleService from "../../../services/role.service";
import MenuService from "../../../services/menu.service";
import AlertService from "../../../services/alert.service";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";

const Permissions = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedRoleName, setSelectedRoleName] = useState();
  const authContext = useContext(AuthContext);

  const loadRoles = useCallback(async () => {
    setLoading(true);
    const data = await RoleService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setRoles(
        data.data.map((m) => ({
          id: m.id,
          key: m.id,
          label: m.roleName,
          data: m,
        }))
      );
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  const loadMenus = useCallback(async () => {
    setLoading(true);
    const data = await MenuService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setMenus(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadRoles();
    loadMenus();
  }, [loadRoles, loadMenus, authContext]);

  const loadRolePermissions = async (id) => {
    setRoleData([]);
    setLoading(true);
    const data = await RoleService.GetPermissions(id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      await processData(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  };

  const assignPermissions = async (e) => {
    if (e.value && e.value != null) {
      let selectedRoles = roles.filter((f) => f.id === e.value);
      if (selectedRoles.length > 0) {
        setSelectedRoleName(selectedRoles[0].label);
        await loadRolePermissions(e.value);
      }
    }
  };

  const processData = async (rolePermissions) => {
    if (menus && menus.length) {
      for (let menu of menus) {
        let menuPermissions = rolePermissions.filter(
          (f) => f.menuId === menu.id
        );
        let obj = {};
        obj.menuId = menu.id;
        obj.menuName = menu.menuName;
        obj.displayText = menu.displayText;
        obj.selectAll = menu.permissions.length === menuPermissions.length;
        obj.permissions = menu.permissions.map((m) => ({
          id: m.id,
          permission: m.permission,
          selected:
            menuPermissions.filter((f) => f.permissionId === m.id).length > 0,
        }));
        setRoleData((prevData) => {
          return [...prevData, obj];
        });
      }
    }
  };

  const clearData = async () => {
    setSelectedRole(0);
    setSelectedRoleName("");
    setRoleData([]);
  };

  const onCancelHandler = async (e) => {
    e.preventDefault();
    await clearData();
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let payload = [];
      let selectedData = roleData.filter(
        (f) => f.permissions.filter((p) => p.selected === true).length > 0
      );
      for (let d of selectedData) {
        let selectedPermissions = d.permissions.filter(
          (pm) => pm.selected === true
        );
        for (let m of selectedPermissions) {
          payload.push({
            roleId: selectedRole,
            menuId: d.menuId,
            permissionId: m.id,
          });
        }
      }
      let data = await RoleService.SavePermission(payload, authContext);
      if (data.isSuccess) {
        await clearData();
        AlertService.success("Role permission details saved successfully!");
      } else {
        AlertService.error(data.errorMessage);
      }
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <h2>Role Permissions</h2>
        <div className="row m-0">
          <div className="col-lg-2 d-none d-lg-block">
            <Tree
              value={roles}
              selectionMode="single"
              selectionKeys={selectedRole}
              onSelectionChange={async (e) => {
                setSelectedRole(e.value);
                await assignPermissions(e);
              }}
              className="w-full"
              loading={loading}
            />
          </div>
          <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
            <div className="row pt-4 d-block d-lg-none">
              <div className="col-12">
                <select
                  className="form-control"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  {roles &&
                    roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {selectedRole !== undefined &&
              selectedRole !== null &&
              selectedRole > 0 && (
                <div className="border-top border-left border-right">
                  <h4 className="p-1 border-bottom">
                    Selected Role : {selectedRoleName}
                  </h4>
                  {roleData &&
                    roleData.length > 0 &&
                    roleData.map((menu) => (
                      <div key={menu.menuId} className="row m-0 border-bottom">
                        <div className="col-md-2 col-sm-2 col-xs-12 label border-right p-1">
                          <Checkbox
                            onChange={(e) => {
                              setRoleData((prevData) => {
                                let prevSteps = prevData.filter(
                                  (f) => f.menuId === menu.menuId
                                );
                                if (prevSteps.length > 0) {
                                  prevSteps[0].selectAll = e.checked;
                                  prevSteps[0].permissions.forEach(
                                    (f) => (f.selected = e.checked)
                                  );
                                }
                                return [...prevData];
                              });
                            }}
                            checked={menu.selectAll}
                          ></Checkbox>{" "}
                          {menu.menuName}
                        </div>
                        {menu.permissions &&
                          menu.permissions.length > 0 &&
                          menu.permissions.map((permission, i) => (
                            <div
                              key={i}
                              className="col-md-1 col-sm-2 col-xs-12 border-right p-1"
                            >
                              <Checkbox
                                onChange={(e) => {
                                  permission.selected = e.checked;
                                  setRoleData((prevData) => {
                                    let prevSteps = prevData.filter(
                                      (f) => f.menuId === menu.menuId
                                    );
                                    if (prevSteps.length > 0) {
                                      prevSteps[0].permissions.filter(
                                        (f) => f.id === permission.id
                                      )[0].selected = e.checked;
                                    }
                                    prevSteps[0].selectAll =
                                      menu.permissions.length ===
                                      prevSteps[0].permissions.filter(
                                        (f) => f.selected === true
                                      ).length;
                                    return [...prevData];
                                  });
                                }}
                                checked={permission.selected}
                              ></Checkbox>{" "}
                              {permission.permission}
                            </div>
                          ))}
                      </div>
                    ))}
                  <div className="row pt-2 mb-3">
                    <div className="col-md-12 col-sm-12">
                      <div className="text-center pt-4">
                        <button
                          type="button"
                          className="submit-button"
                          onClick={onSubmitHandler}
                          disabled={loading}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="cancel-button ml-3"
                          onClick={onCancelHandler}
                          disabled={loading}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Permissions;
