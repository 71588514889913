import request from "./request";

const CatalogueService = {
  GetAll: async (searchText, context) => {
    return await request.sendRequest(`/incident/all/search/${searchText}`, context.token);
  },
  GetIncidents: async (searchText, context) => {
    return await request.sendRequest(`/incident/search/${searchText}`, context.token);
  },
  GetAllByCourseId: async (id, searchText, context) => {
    return await request.sendRequest(`/incident/all/${id}/${searchText}`, context.token);
  },
  GetCategories: async (context) => {
    return await request.sendRequest(`/incident/all/categories`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/incident/${id}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/incident`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/incident/${id}`, context.token);
  },
};

export default CatalogueService;
