import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import Header from "../header";
import { InputOtp } from "primereact/inputotp";
import "../identity.css";

const MFA = () => {
  const navigate = useNavigate();
  const [codeIsValid, setCodeIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [codeValue, setCodeValue] = useState();

  const authContext = useContext(AuthContext);

  const validateVerified = useCallback(() => {
    if (authContext.mfaVerified) {
      navigate("/");
    }
  }, [authContext, navigate]);

  useEffect(() => {
    validateVerified();
  }, [validateVerified, authContext]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!codeValue) {
      setCodeIsValid(false);
      return false;
    }

    if (codeIsValid) {
      try {
        setLoading(true);
        const result = await UserService.VerifyQrCode(codeValue, authContext);
        setLoading(false);
        if (result.isSuccess) {
          authContext.setMFAVerfied(true);
          setLoading(false);
          navigate("/");
        } else {
          setLoading(false);
          AlertService.error(result.errorMessage);
        }
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onOTPHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const result = await UserService.SendOTP(authContext);
      setLoading(false);
      if (result.isSuccess) {
        setLoading(false);
        AlertService.success(result.message);
        navigate("/verifyotp");
      } else {
        setLoading(false);
        AlertService.error(result.errorMessage);
      }
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="identity-container">
        <Header />
        <div className="row identity-wrapper">
          <div className="col-xl-6 col-lg-12">
            <div className="identity-form text-center">
              <h1>Two Factor Verification</h1>
              <form onSubmit={onSubmitHandler}>
                <div className="row m-0">
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                    <small>
                      Please provide the One-Time Password code generated by{" "}
                      <br />
                      <b>Microsoft Authenticator</b> in the textbox below.
                    </small>
                  </div>
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                </div>
                <div className="row m-0">
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 m-auto">
                    <InputOtp
                      onChange={(e) => {
                        setCodeValue(e.value);
                        setLoading(false);
                      }}
                      onKeyUp={() => setLoading(false)}
                      length={6}
                      disabled={loading}
                      integerOnly
                    />
                  </div>
                  <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                </div>
                <div className="text-center pt-4">
                  <button
                    type="submit"
                    className="identity-button"
                    disabled={loading}
                  >
                    Verify
                  </button>
                </div>
                <div className="text-center pt-4">
                  <small>
                    I can't use my Microsoft Authenticator app right now
                  </small>
                </div>
                <div className="text-center pt-4">
                  <button
                    type="button"
                    className="identity-button"
                    disabled={loading}
                    onClick={onOTPHandler}
                  >
                    Send Code via Email
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 d-none d-xl-block identity-image">
            <img alt="QrCode" src="assets/img/login.png"></img>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MFA;
