import request from "./request";

const CourseService = {
  GetAll: async (context) => {
    return await request.sendRequest(`/course/all`, context.token);
  },
  GetCourses: async (context) => {
    return await request.sendRequest(`/course/all/courses`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/course/${id}`, context.token);
  },
  GetIncidents: async (id, searchText, context) => {
    return await request.sendRequest(`/course/incidents/${id}/${searchText}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/course`, payload, context);
  },
  SaveIncidents: async (payload, context) => {
    return await request.sendPostRequest(`/course/incidents`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/course/${id}`, context.token);
  },
};

export default CourseService;
