import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalQuantity: 0,
    totalPrice: 0,
    currency: '₹'
  },
  reducers: {
    addCartItem(state, action) {

      state.items = action.payload;
      state.totalPrice = state.items.reduce((total, item) => { return total + item.totalPrice }, 0);
      state.totalQuantity = state.items.reduce((total, item) => { return total + item.quantity }, 0);
      if (state.items.length) state.currency = state.items[0].currency;
    },
    removeCartItems(state) {
      state.items = [];
      state.totalPrice = 0;
      state.totalQuantity = 0;
    },
    setItemToCart(state, action) {

      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      existingItem.quantity = newItem.quantity;
      existingItem.totalPrice = existingItem.quantity * existingItem.rate;
      state.totalPrice = state.items.reduce((total, item) => { return total + item.totalPrice }, 0);
      state.totalQuantity = state.items.reduce((total, item) => { return total + item.quantity }, 0);
      cartSlice.caseReducers.updateCart(state);
    },
    addItemToCart(state, action) {

      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      state.totalPrice += newItem.discountRate;
      state.totalQuantity++;
      state.currency = newItem.currency;
      if (!existingItem) {
        state.items.push({
          id: newItem.id,
          rate: newItem.discountRate,
          currency: newItem.currency,
          quantity: 1,
          totalPrice: newItem.discountRate,
          title: newItem.itemName,
          image: newItem.imagePath[0],
          isCODAvailable: newItem.isCODAvailable,
          turnAround: newItem.turnAround,
          onDemand: newItem.onDemand
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice = existingItem.totalPrice + newItem.discountRate;
      }
      cartSlice.caseReducers.updateCart(state);
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      state.totalQuantity--;
      state.totalPrice -= existingItem.rate;
      if (existingItem.quantity === 1) {
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice = existingItem.totalPrice - existingItem.rate;
      }
      cartSlice.caseReducers.updateCart(state);
    },
    removeItem(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      state.totalQuantity = state.totalQuantity - existingItem.quantity;
      state.totalPrice = state.totalPrice - existingItem.totalPrice;
      state.items = state.items.filter((item) => item.id !== id);
      cartSlice.caseReducers.updateCart(state);
    },
    promotionDiscount(state) {
      state.totalPrice -= 15;
    },
    addShippingCost(state) {
      state.totalPrice += 15.99;
    },
    cleanData(state) {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
    updateCart(state) {
      const authContext = JSON.parse(localStorage.getItem('userinfo'));
      if (authContext && authContext.user && authContext.user.id) {
      }
    }
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;
