import { createSlice } from "@reduxjs/toolkit";

const apiSlice = createSlice({
    name: "api",
    initialState: {
        keyword: "",
        category: 0,
        item: {},
        apiProcess: false,
        data: [],
        comments: [],
    },
    reducers: {
        apiCategory(state, action) {
            state.category = action.payload;
        },
        apiKeyword(state, action) {
            state.keyword = action.payload;
        },
        apiItem(state, action) {
            state.item = action.payload;
        },
        startApiProcess(state) {
            state.apiProcess = true;
        },
        stopApiProcess(state) {
            state.apiProcess = false;
        },
        data(state, action) {
            state.data = action.payload;
        },
        apiComments(state, action) {
            state.comments = action.payload.items;
        },
    },
});

export const apiActions = apiSlice.actions;
export default apiSlice;
