import * as React from "react";
import { useCookies } from "react-cookie";
const storeKey = process.env.REACT_APP_USER_KEY;
const options = { maxAge: 5184000 };

const AuthContext = React.createContext({
  token: "",
  refreshToken: "",
  userId: 0,
  roleId: 0,
  isLoggedIn: false,
  isAnswered: false,
  resetPassword: false,
  mfaSetupCompleted: false,
  mfaVerified: false,
  displayName: false,
  login: (
    token,
    refresh_token,
    userId,
    roleId,
    isAnswered,
    resetPassword,
    mfaSetupCompleted,
    mfaVerified,
    displayName,
    permissions
  ) => { },
  logout: () => { },
  setAnswered: (flag) => { },
  setMFAVerfied: (flag) => { },
  setMFASetupCompleted: (flag) => { },
  setResetPasswordCompleted: (flag) => { },
  setDisplayName: (flag) => { },
  setPermissions: (data) => { },
  getPermissions: (menuName) => { },
});

export const AuthContextProvider = ({ children }) => {
  let initToken,
    initRefreshToken,
    initUserId,
    initRoleId,
    initIsAnswered,
    initResetPassword,
    initMFASetupCompleted,
    initMFAVerfied,
    initDisplayName,
    initPermissions;

  const [cookies, setCookie, removeCookie] = useCookies();

  const sessionInfo = cookies[storeKey];
  const permissionsInfo = cookies[storeKey + "_per"];

  if (sessionInfo) {
    const uObj =
      sessionInfo instanceof Object ? sessionInfo : JSON.parse(sessionInfo);
    initToken = uObj.token;
    initRefreshToken = uObj.refreshToken;
    initUserId = uObj.userId;
    initRoleId = uObj.roleId;
    initIsAnswered = uObj.isAnswered;
    initResetPassword = uObj.resetPassword;
    initMFASetupCompleted = uObj.mfaSetupCompleted;
    initMFAVerfied = uObj.mfaVerified;
    initDisplayName = uObj.displayName;
  }

  if (permissionsInfo) {
    const pObj =
      permissionsInfo instanceof Object
        ? permissionsInfo
        : JSON.parse(permissionsInfo);
    initPermissions = pObj.permissions;
  }

  const [token, setToken] = React.useState(initToken);
  const [refreshToken, setRefreshToken] = React.useState(initRefreshToken);
  const [userId, setUserId] = React.useState(initUserId);
  const [roleId, setRoleId] = React.useState(initRoleId);
  const [isAnswered, setIsAnswered] = React.useState(initIsAnswered);
  const [resetPassword, setResetPassword] = React.useState(initResetPassword);
  const [mfaSetupCompleted, setMFASetupCompleted] = React.useState(
    initMFASetupCompleted
  );
  const [mfaVerified, setMFAVerfied] = React.useState(initMFAVerfied);
  const [displayName, setDisplayName] = React.useState(initDisplayName);
  const [permissions, setPermissions] = React.useState(initPermissions);

  const userIsLoggedIn = !!token;

  const loginHandler = (
    token,
    refresh_token,
    userId,
    roleId,
    isAnswered,
    resetPassword,
    mfaSetupCompleted,
    mfaVerified,
    displayName,
    permissions
  ) => {
    setToken(token);
    setRefreshToken(refresh_token);
    setUserId(userId);
    setRoleId(roleId);
    setIsAnswered(isAnswered);
    setResetPassword(resetPassword);
    setMFASetupCompleted(mfaSetupCompleted);
    setMFAVerfied(mfaVerified);
    setDisplayName(displayName);
    setPermissions(permissions);
    setCookie(
      storeKey,
      JSON.stringify({
        token: token,
        refreshToken: refresh_token,
        userId: userId,
        roleId: roleId,
        isAnswered: isAnswered,
        resetPassword: resetPassword,
        mfaSetupCompleted: mfaSetupCompleted,
        mfaVerified: mfaVerified,
        displayName: displayName,
      }),
      options
    );
    setCookie(
      storeKey + "_per",
      JSON.stringify({
        permissions: permissions,
      }),
      options
    );
  };

  const logoutHandler = () => {
    removeCookie(storeKey);
    removeCookie(storeKey + "_per");
    setToken(null);
    setRefreshToken(null);
    setUserId(null);
    setPermissions(null);
  };

  const mfaHandler = (flag) => {
    setMFAVerfied(flag);
    const uObj =
      sessionInfo instanceof Object ? sessionInfo : JSON.parse(sessionInfo);
    uObj.mfaVerified = flag;
    setCookie(storeKey, JSON.stringify(uObj), options);
  };

  const mfaSetupHandler = (flag) => {
    setMFASetupCompleted(flag);
    const uObj =
      sessionInfo instanceof Object ? sessionInfo : JSON.parse(sessionInfo);
    uObj.mfaSetupCompleted = flag;
    setCookie(storeKey, JSON.stringify(uObj), options);
  };

  const resetPasswordHandler = (flag) => {
    setResetPassword(flag);
    const uObj =
      sessionInfo instanceof Object ? sessionInfo : JSON.parse(sessionInfo);
    uObj.resetPassword = flag;
    setCookie(storeKey, JSON.stringify(uObj), options);
  };

  const questionHandler = (flag) => {
    setIsAnswered(flag);
    const uObj =
      sessionInfo instanceof Object ? sessionInfo : JSON.parse(sessionInfo);
    uObj.isAnswered = flag;
    setCookie(storeKey, JSON.stringify(uObj), options);
  };

  const setPermissionHandler = (data) => {
    setPermissions(data);
    if (permissionsInfo) {
      const pObj =
        permissionsInfo instanceof Object
          ? permissionsInfo
          : JSON.parse(permissionsInfo);
      pObj.permissions = data;
      setCookie(storeKey + "_per", JSON.stringify(pObj), options);
    } else {
      setCookie(
        storeKey + "_per",
        JSON.stringify({
          permissions: data,
        }),
        options
      );
    }
  };

  const getPermissionHandler = (menuName) => {
    if (permissionsInfo && permissionsInfo != null) {
      const pObj =
        permissionsInfo instanceof Object
          ? permissionsInfo
          : JSON.parse(permissionsInfo);
      return pObj && pObj.permissions && pObj.permissions.filter((f) => f.menuName.toLowerCase() === menuName.toLowerCase());
    }
    return [];
  };

  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    userId: userId,
    roleId: roleId,
    isLoggedIn: userIsLoggedIn,
    isAnswered: isAnswered,
    resetPassword: resetPassword,
    mfaSetupCompleted: mfaSetupCompleted,
    mfaVerified: mfaVerified,
    displayName: displayName,
    permissions: permissions,
    login: loginHandler,
    logout: logoutHandler,
    setAnswered: questionHandler,
    setMFAVerfied: mfaHandler,
    setMFASetupCompleted: mfaSetupHandler,
    setResetPasswordCompleted: resetPasswordHandler,
    setPermissions: setPermissionHandler,
    getPermissions: getPermissionHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
