import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AuthContext from "./auth/auth-context";
import Layout from "./components/layout";
import Questions from "./components/identity/questions/questions";
import Reset from "./components/identity/reset/reset";
import Logout from "./components/identity/logout/logout";
import QrCode from "./components/identity/mfa/qrcode";
import MFA from "./components/identity/mfa/mfa";
import MFAQ from "./components/identity/mfa/mfaq";
import VerifyOTP from "./components/identity/mfa/verifyotp";
import OTPQRVerify from "./components/identity/mfa/otpverify";
import ReConfigure from "./components/identity/mfa/reconfigure";
import Profile from "./components/identity/profile/profile";
import Role from "./components/identity/role/role";
import Permissions from "./components/identity/role/permissions";
import Answers from "./components/identity/profile/answers";
import ChangePassword from "./components/identity/profile/changepassword";
import EditProfile from "./components/identity/profile/editprofile";
import Dashboard from "./components/app/dashboard/dashboard";
import Catalogues from "./components/app/catalogues/catalogues";
import Bays from "./components/app/bays/bays";
import Assessments from "./components/app/assessment/assessment";
import Support from "./components/app/support/support";
import Courses from "./components/app/courses/courses";
import Report from "./components/app/reports/reports";
import AddCatalogue from "./components/app/catalogues/add";
import Users from "./components/identity/users/users";
import AddCourse from "./components/app/courses/add";
import Documents from "./components/app/documents/documents";
import AddDocument from "./components/app/documents/add";
import AddSupport from "./components/app/support/add";
import Agent from "./components/app/agents/agents";
import CourseIncidents from "./components/app/courses/courseIncidents";
import Learners from "./components/identity/learners/learners";
import UploadLearners from "./components/identity/learners/upload";
// import ViewTicket from "./components/app/support/view";

function App() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.isLoggedIn === false) {
      navigate("login");
    }
  }, [authContext, navigate]);
  /*
    useEffect(() => {
      const handleContextmenu = e => {
        e.preventDefault()
      }
      const handleKeyEvents = e => {
        if (e.key === 123) {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'I') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'C') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'J') {
          e.preventDefault();
        }
        if (e.ctrlKey && e.key === 'U') {
          e.preventDefault();
        }
      }
      document.addEventListener('contextmenu', handleContextmenu)
      document.addEventListener('keydown', handleKeyEvents)
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
        document.removeEventListener('keydown', handleContextmenu)
      }
    }, [])
  */
  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        {authContext.isLoggedIn && (<Route path="qrcode" exact element={<QrCode></QrCode>} />)}
        {authContext.isLoggedIn && (<Route path="configureqr/:code" exact element={<ReConfigure></ReConfigure>} />)}
        {authContext.isLoggedIn && (<Route path="mfa" exact element={<MFA></MFA>} />)}
        {authContext.isLoggedIn && (<Route path="mfaq" exact element={<MFAQ></MFAQ>} />)}
        {authContext.isLoggedIn && (<Route path="verifyotp" exact element={<VerifyOTP></VerifyOTP>} />)}
        {authContext.isLoggedIn && (<Route path="configmfaotp" exact element={<OTPQRVerify></OTPQRVerify>} />)}
        {authContext.isLoggedIn && (<Route path="questions" exact element={<Questions></Questions>} />)}
        {authContext.isLoggedIn && (<Route path="reset" exact element={<Reset></Reset>} />)}
        {authContext.isLoggedIn && (<Route path="logout" exact element={<Logout></Logout>} />)}
        {authContext.isLoggedIn && (<Route path="profile" exact element={<Profile></Profile>} />)}
        {authContext.isLoggedIn && (<Route path="profile/edit" exact element={<EditProfile></EditProfile>} />)}
        {authContext.isLoggedIn && (<Route path="roles" exact element={<Role></Role>} />)}
        {authContext.isLoggedIn && (<Route path="agents" exact element={<Agent></Agent>} />)}
        {authContext.isLoggedIn && (<Route path="permissions" exact element={<Permissions></Permissions>} />)}
        {authContext.isLoggedIn && (<Route path="profile" exact element={<Profile></Profile>} />)}
        {authContext.isLoggedIn && (<Route path="change" exact element={<ChangePassword></ChangePassword>} />)}
        {authContext.isLoggedIn && (<Route path="securityanswers" exact element={<Answers></Answers>} />)}
        {authContext.isLoggedIn && (<Route path="dashboard" exact element={<Dashboard></Dashboard>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues" exact element={<Catalogues></Catalogues>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/course/:id" exact element={<Catalogues></Catalogues>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/add" exact element={<AddCatalogue></AddCatalogue>} />)}
        {authContext.isLoggedIn && (<Route path="catalogues/edit/:id" exact element={<AddCatalogue></AddCatalogue>} />)}
        {authContext.isLoggedIn && (<Route path="bays" exact element={<Bays></Bays>} />)}
        {authContext.isLoggedIn && (<Route path="assessments" exact element={<Assessments></Assessments>} />)}
        {authContext.isLoggedIn && (<Route path="support" exact element={<Support></Support>} />)}
        {authContext.isLoggedIn && (<Route path="support/add" exact element={<AddSupport></AddSupport>} />)}
        {/* {authContext.isLoggedIn && (<Route path="support/view/:id" exact element={<ViewTicket></ViewTicket>} /> )} */}
        {authContext.isLoggedIn && (<Route path="courses" exact element={<Courses></Courses>} />)}
        {authContext.isLoggedIn && (<Route path="courses/add" exact element={<AddCourse></AddCourse>} />)}
        {authContext.isLoggedIn && (<Route path="courses/edit/:id" exact element={<AddCourse></AddCourse>} />)}
        {authContext.isLoggedIn && (<Route path="courses/incidents/:id" exact element={<CourseIncidents></CourseIncidents>} />)}
        {authContext.isLoggedIn && (<Route path="reports" exact element={<Report></Report>} />)}
        {authContext.isLoggedIn && (<Route path="users" exact element={<Users></Users>} />)}
        {authContext.isLoggedIn && (<Route path="documents" exact element={<Documents></Documents>} />)}
        {authContext.isLoggedIn && (<Route path="documents/add" exact element={<AddDocument></AddDocument>} />)}
        {authContext.isLoggedIn && (<Route path="documents/edit/:id" exact element={<AddDocument></AddDocument>} />)}
        {authContext.isLoggedIn && (<Route path="learners" exact element={<Learners></Learners>} />)}
        {authContext.isLoggedIn && (<Route path="learners/upload" exact element={<UploadLearners></UploadLearners>} />)}
        <Route path="/*" element={<Navigate to="/" push={true} />} />
      </Routes>
    </Layout>
  );
}

export default App;
