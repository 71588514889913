import request from "./request";

const SupportService = {
  GetAll: async (status, skip, take, context) => {
    return await request.sendRequest(`/ticket/user/all/${status}/${skip}/${take}`, context.token);
  },
  GetTickets: async (status, skip, take, context) => {
    return await request.sendRequest(`/ticket/assignee/all/${status}/${skip}/${take}`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/ticket/${id}`, context.token);
  },
  GetComments: async (id, context) => {
    return await request.sendRequest(`/ticket/comments/${id}`, context.token);
  },
  GetFile: async (id, context) => {
    return await request.sendRequest(`/ticket/file/${id}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendFormDataRequest(`/ticket`, payload, context);
  },
  SaveComment: async (payload, context) => {
    return await request.sendFormDataRequest(`/ticket/comment`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/ticket/${id}`, context.token);
  },
};

export default SupportService;
