import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import Header from "../header";
import { InputOtp } from "primereact/inputotp";
import "../identity.css";

const QrCode = () => {
  const navigate = useNavigate();
  const [codeIsValid, setCodeIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [codeValue, setCodeValue] = useState();

  const authContext = useContext(AuthContext);

  const validateSetupCompleted = useCallback(() => {
    if (authContext.mfaSetupCompleted) {
      navigate("/");
    }
  }, [authContext, navigate]);

  useEffect(() => {
    validateSetupCompleted();
  }, [validateSetupCompleted, authContext]);

  const loadQrCode = useCallback(async () => {
    setLoading(true);
    const data = await UserService.GetQrCode(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setQrCode(data.data.qrCodeSetupImageUrl);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadQrCode();
  }, [loadQrCode, authContext]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!codeValue) {
      setCodeIsValid(false);
      return false;
    }

    if (codeIsValid) {
      try {
        setLoading(true);
        const result = await UserService.VerifyQrCode(codeValue, authContext);
        setLoading(false);
        if (result.isSuccess) {
          setLoading(false);
          authContext.setMFASetupCompleted(true);
          if (authContext.resetPassword) {
            navigate("/reset");
          } else if (authContext.isAnswered === false) {
            navigate("/questions");
          } else {
            navigate("/logout");
          }
        } else {
          setLoading(false);
          AlertService.error(result.errorMessage);
        }
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="identity-container">
        <Header />
        <div className="row identity-wrapper">
          <div className="col-xl-6 col-lg-12">
            {!loading && (
              <div className="identity-form text-center">
                <h1>Two Factor Verification Setup</h1>
                <small>
                  Please scan the QR code displayed below using Microsoft
                  Authenticator.
                </small>
                <br />
                <img alt="QrCode" src={qrCode}></img>
                <form onSubmit={onSubmitHandler}>
                  <div className="row m-0">
                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                      <small>
                        Once your email is added to the list, verify the
                        One-Time Password code generated by Microsoft
                        Authenticator in the textbox below.
                      </small>
                    </div>
                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  </div>
                  <div className="row m-0">
                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 m-auto">
                    <InputOtp
                      onChange={(e) => {
                        setCodeValue(e.value);
                        setLoading(false);
                      }}
                      onKeyUp={() => setLoading(false)}
                      length={6}
                      disabled={loading}
                      integerOnly
                    />
                    </div>
                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                  </div>
                  <div className="text-center pt-2">
                    <button
                      type="submit"
                      className="identity-button"
                      disabled={loading}
                    >
                      Verify & Complete Setup
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className="col-lg-6 d-none d-xl-block identity-image">
            <img alt="QrCode" src="assets/img/login.png"></img>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QrCode;
