import { Fragment, useEffect, useState } from "react";
import ViewObjective from "./viewObjective";
import ViewStep from "./viewStep";

const View = ({ incident, clearSelection, editable, courseId, courseName }) => {
  const [hasObjectives, setHasObjectives] = useState(incident && incident.objectives && incident.objectives.length > 0);

  useEffect(() => {
    setHasObjectives(incident && incident.objectives && incident.objectives.length > 0);
  }, [incident]);

  return (
    <Fragment>
      {incident && (
        <div className="row m-0">
          <div className={hasObjectives ? "col-md-9 col-sm-12" : "col-md-12 col-sm-12"}>
            <div className="row m-0">
              <div className="col-12 course-title">
                {courseName}
                <button type="button" className="btn-incident-back" onClick={clearSelection}>
                  <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                </button>
              </div>
            </div>
            <div className="incident-view-container">
              <div className="row m-0">
                <div className="col-12">
                  <span className="label">Incident Title: </span> {incident.incidentTitle}
                </div>
                {editable &&
                  <div className="col-12">
                    <span className="label">Tags: </span> {incident.tags}
                  </div>
                }
                {editable &&
                  <div className="col-12">
                    <span className="label">Category: </span> {incident.category}
                  </div>
                }
                <div className="col-12">
                  <span className="label">Story Board</span> <br />
                  {incident.storyBoard}
                </div>
                <div className="col-12">
                  <span className="label">Task</span> <br />
                  {incident.task}
                </div>
                <div className="col-12 d-lg-none d-md-none d-sm-block d-xs-block">
                  <span className="label">5 O's</span> <br />
                  {incident &&
                    incident.objectives &&
                    incident.objectives.length > 0 &&
                    incident.objectives.map((objective, i) => (
                      <div key={i} className="mt-3">
                        <span>{objective.objectiveName}</span> <br />
                        {objective.description}
                      </div>
                    ))}
                </div>
                <div className="col-12">
                  <span className="label">Solution</span> <br />
                  {incident &&
                    incident.steps &&
                    incident.steps.length > 0 &&
                    incident.steps.map((step, i) => (
                      <ViewStep key={i} step={step.description}></ViewStep>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {hasObjectives &&
            <div className="col-3 objective-view-container">
              {incident &&
                incident.objectives &&
                incident.objectives.length > 0 &&
                incident.objectives.map((objective, i) => (
                  <ViewObjective
                    key={i}
                    objective={objective}
                  ></ViewObjective>
                ))}
            </div>
          }
        </div>
      )}
    </Fragment >
  );
};

export default View;
