import { Fragment, useContext, useState } from "react";
import DocumentService from "../../../services/document.service";
import AuthContext from "../../../auth/auth-context";
import Viewer from "./viewer";
import { Sidebar } from "primereact/sidebar";

const Attachment = ({ no, attachment }) => {
  no++;
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [pdfData, setPDFData] = useState();

  const downloadFile = async () => {
    const data = await DocumentService.GetFile(attachment.id, authContext);
    setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
    setVisible(true);
  };

  return (
    <Fragment>
      <div className="attachment">
        {no}.{" "}
        <button className="btn-icon" onClick={downloadFile} title="Click on the filename to preview the file.">
          {attachment.fileName}
        </button>
        <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
          {pdfData && <Viewer src={pdfData}></Viewer>}
        </Sidebar>
      </div>
    </Fragment>
  );
};

export default Attachment;
